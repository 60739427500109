/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type metadata_metadata = {
    readonly title: string;
    readonly description: string | null;
    readonly sharingImage: string | null;
    readonly " $refType": "metadata_metadata";
};
export type metadata_metadata$data = metadata_metadata;
export type metadata_metadata$key = {
    readonly " $data"?: metadata_metadata$data;
    readonly " $fragmentRefs": FragmentRefs<"metadata_metadata">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "metadata_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sharingImage",
      "storageKey": null
    }
  ],
  "type": "OpengraphMetadata",
  "abstractKey": "__isOpengraphMetadata"
};
(node as any).hash = 'e60403bc55363ec8467d8aba8276ebbb';
export default node;
